<template>
  <div class="music-item-container">
    <!-- 歌曲信息 -->
    <div class="column">
      <!-- 盒子 -->
      <div class="before-box"></div>
      <div class="content">
        <!-- 封面 -->
        <div class="cover" @click="handlePlayerMusic(data)">
          <el-image class="cover-main" :src="data.logo" :fit="'cover'">
            <div slot="error" class="image-slot">
              <img src="@/assets/image/default-music-img.png" alt="" />
            </div>
          </el-image>
          <img class="cover-fixed" src="~@/assets/svg/record-bg.svg" />
        </div>
        <div class="detail">
          <p class="detail-name" v-html="brightenKeyword(data.name, keywordName)">{{ data.name }}</p>
          <p class="detail-info">BPM：{{ data.bpm }}</p>
        </div>
      </div>
      <!-- 盒子 -->
      <div class="after-box"></div>
    </div>
    <!-- 标签 -->
    <div class="column tag" v-html="brightenKeyword(labels.map((i) => i.name).join('，'), keywordName)">
      {{ labels.map((i) => i.name).join('，') }}
    </div>
    <!-- 时长 -->
    <div class="column duration">
      {{ (data.time || data.duration) | formatTimeBySecond }}
    </div>
    <!-- icon -->
    <div class="column icon">
      <!-- <span
        v-if="showCollect"
        @click="handleCollect(data.object_id, data.type || 2, data)"
        class="button collect"
        :class="{ active: data.is_collected === 1 || data.is_collect === 1 }"
      /> -->
      <img
        class="button collect"
        :src="
          require(`@/assets/svg/${
            data.is_collected === 0 ? 'collect' : 'collected'
          }.svg`)
        "
        @click="
          handleMusicCollect(data)
        "
      />
      <span
        v-if="showShare"
        @click="handleShare('', data.object_id, data.type || 2, data.name)"
        class="button share"
      />
      <span
        v-if="showDownload"
        @click="handleDownload(data)"
        class="button download"
      />
      <span
        v-if="showCart"
        @click="handleAddGoodsToCart(data)"
        class="button cart"
      />
      <span
        v-if="showBtnCart"
        class="btn btn-content"
        @click="handleAddGoodsToCart(data,2)"
      >
        <span class="btn-car">加入购物车</span>
      </span>
    </div>
  </div>
</template>

<script>
import Buttons from '@/layout/mixins/Buttons'
import { getToken } from "@/utils/author";

export default {
  name: 'VfMusicItem',
  props: {
    buttons: {
      type: Array,
      default() {
        return ['collect', 'share', 'download', 'cart']
      }
    },
    keywordName: {
      // type: String || Array,
      default: ''
    },
    data: {
      type: null,
      default() {
        return {}
      }
    }
  },
  data() {
    return {}
  },
  computed: {
    showCollect() {
      return this.buttons.includes('collect')
    },
    showShare() {
      return this.buttons.includes('share')
    },
    showDownload() {
      return this.buttons.includes('download')
    },
    showCart() {
      return this.buttons.includes('cart') && !this.showBtnCart
    },
    showBtnCart() {
      return this.buttons.includes('cartBtn')
    },
    labels() {
      const { labels, label } = this.data
      return (labels || label || []).slice(0, 3)
    }
  },
  components: {},
  mixins: [Buttons],
  methods: {
    async handleMusicCollect(item) {
      if (!(getToken() && getToken() !== '')) {
        this.$store.dispatch('user/openLogin')
        return
      }
      item.is_collected = item.is_collected === 0 ? 1 : 0
      await this.handleCollect(item.object_id, item.type || 2, {
        ...item,
        type: 2,
        labels: item.label
      }, false)
    },
    /**
     * 搜索关键字高亮显示
    */
    brightenKeyword(val, keyword) {
      // var Reg = new RegExp(keyword, 'g');
      // val = val+''
      // if (val) {
      //     return val.replace(Reg, `<span style="color:red">${keyword}</span>`);
      // }

      if(typeof(keyword) == 'string'){
        keyword = keyword.trim()
        var Reg = new RegExp(keyword, 'g');
        val = val+''
        
        if (val) {
            return val.replace(Reg, `<span style="color:red">${keyword}</span>`);
        }
      }else{
        keyword.map((keyitem)=>{
          var Regs = new RegExp(keyitem, 'g');
          val = val+''
          if (val) {
            val = val.replace(Regs, `<span style="color:red">${keyitem}</span>`);
          }
        })
        return val
      }
      // if (keyword.length > 0) {
      //   let keywordArr = keyword.split("");
      //   val = val + "";
      //   keywordArr.forEach(item => {
      //     if (val.indexOf(item) !== -1 && item !== "") {
      //       val = val.replace(
      //         new RegExp(item,'g'),
      //         '<font color="red">' + item + "</font>"
      //       );
      //     }
      //   });
      //   return val;
      // } else {
      //   return val;
      // }
    }
  }
}
</script>

<style lang="scss" scoped>
.music-item-container {
  height: 104px;
  padding: 12px 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .column {
    vertical-align: middle;
  }
  .before-box {
    @include size(20px, 100%);
    border-radius: 12px 0 0 12px;
    position: absolute;
    top: 0;
    left: -20px;
    background: #f5f6fa;
    display: none;
  }
  .after-box {
    @include size(20px, 100%);
    border-radius: 0 12px 12px 0;
    position: absolute;
    top: 0;
    right: -20px;
    background: #f5f6fa;
    display: none;
  }
  .content {
    height: 100%;
    @include center-middle-flex($justify: flex-start);
    .cover {
      @include custom-position($position: relative);
      @include size(107px, 80px);
      // margin-left: 20px;
      &-main {
        @include square(80px);
        @include custom-position($position: absolute, $zindex: 2);
        box-shadow: 0px 0px 16px 0px rgba(33, 48, 83, 0.36);
        border-radius: 12px;
        background-color: transparent;
        img {
          width: 100%;
          height: 100%;
        }
      }
      &-fixed {
        @include square(71px);
        @include custom-position(
          $position: absolute,
          $right: 0,
          $top: 50%,
          $zindex: 1
        );
        transform: translateY(-50%);
      }
    }
    .detail {
      margin-left: 16px;
      &-name {
        @include font-bold($size: $font-size-md);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 25px;
      }
      &-info {
        @include font-normal($color: rgba($font-minor-color, 0.48));
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 20px;
        margin-top: 4px;
      }
    }
  }
  .tag {
    overflow: hidden;
    text-overflow: ellipsis;
    .vf-tag-text {
      @include font-normal($size: 16px, $color: rgba($font-minor-color, 0.86));
      margin-right: 6px;
    }
  }
  .duration {
    @include font-normal(
      $size: $font-size-sl,
      $color: rgba($font-minor-color, 0.8)
    );
  }
  .icon {
    text-align: right;
    .button {
      @include square(25px);
      cursor: pointer;
      background-size: cover;
      background-repeat: no-repeat;
      vertical-align: middle;
      // &.collect {
      //   background-image: url('~@/assets/svg/collect-icon-music.svg');
      //   &:hover,
      //   &:active,
      //   &:link,
      //   &.active {
      //     background-image: url('~@/assets/svg/collect-icon-music-select.svg');
      //   }
      // }

      &.share {
        background-image: url('~@/assets/svg/share-icon-music.svg');

        &:hover,
        &:active,
        &:link,
        &.active {
          background-image: url('~@/assets/svg/share-icon-music-select.svg');
        }
      }

      &.download {
        background-image: url('~@/assets/svg/download-icon-music.svg');

        &:hover,
        &:active,
        &:link,
        &.active {
          background-image: url('~@/assets/svg/download-icon-music-select.svg');
        }
      }

      &.cart {
        background-image: url('~@/assets/svg/cart-icon-music.svg');

        &:hover,
        &:active,
        &:link,
        &.active {
          background-image: url('~@/assets/svg/cart-icon-music-select.svg');
        }
      }
      & + .button {
        margin-left: 21px;
      }
    }
  }
  .icon:nth-last-of-type(1) {
    margin-right: 0;
  }
  .btn {
    @include font-normal($color: $theme-color);
    width: 120px;
    display: inline-block;
    cursor: pointer;
    &-content {
      @include center-middle-flex($justify: flex-end);
      display: inline-block;
      vertical-align: middle;
    }
    &-car {
      border: 1px solid $theme-color;
      border-radius: 2px;
      padding: 6px 10px;
    }
  }
}
</style>
