<template>
  <div class="vf-pagination-container">
    <!-- 'page-hidden': hiddenPage, -->
    <el-pagination background :class="{
      ...positionClass
    }" :total="total" :current-page.sync="currentPage" :page-size.sync="pageSize" :layout="layout" v-bind="$attrs"
      @size-change="handleSizeChange" @current-change="handleCurrentChange" :prev-text="hiddenPage ? '上一页' : ''"
      :next-text="hiddenPage ? '下一页' : ''">
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    // 控制分页器位置：'left'居左 'center'居中 'right'居右
    position: {
      type: String,
      default: 'center'
    },
    total: {
      required: true,
      type: Number
    },
    page: {
      type: Number,
      default: 1
    },
    hiddenPage: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Number,
      default: 20
    },
    layout: {
      type: String,
      default: 'prev, pager, next, slot'
    },
    isShowPager: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.page
      },
      set(val) {
        this.$emit('update:page', val)
      }
    },
    pageSize: {
      get() {
        return this.limit
      },
      set(val) {
        this.$emit('update:limit', val)
      }
    },
    positionClass: {
      get() {
        const item = {}
        item['pagination-' + this.position] = true
        return item
      }
    },
    pageCount: {
      get() {
        return Math.floor(this.total / this.limit)
      }
    }
  },
  methods: {
    handleSizeChange(val) {
      this.$emit('pagination', { page: this.currentPage, limit: val })
    },
    handleCurrentChange(val) {
      this.$emit('pagination', { page: val, limit: this.pageSize })
    }
  }
}
</script>

<style lang="scss" scoped>
.vf-pagination-container {
  width: 100%;

  .pagination-left {
    justify-content: flex-start;
  }

  .pagination-center {
    justify-content: center;
  }

  .pagination-right {
    justify-content: flex-end;
  }

  .page-hidden {
    /deep/ {
      .el-pager {
        display: none;
      }
    }
  }

  /deep/ .el-pagination {
    display: flex;

    li {
      width: 40px !important;
      height: 40px !important;
      line-height: 40px;
      background: none !important;
      border-radius: 3px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      @include font-bold($size: 14px !important,
        $color: rgba(0, 0, 0, 0.75) !important);
    }

    li:hover {
      color: $theme-color !important;
      border-color: $theme-color;
    }

    li:not(.number) {
      border: none;
    }

    li.number {
      margin: 0 10px;
    }

    .active {
      color: $theme-color !important;
      border: 1px solid $theme-color;
    }

    button {
      background: none;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.25);
      width: 80px !important;
      height: 40px !important;
      padding-left: 6px;
      padding-right: 6px;
      margin: 0;

      i {
        font-size: 18px;
      }
    }

    button[disabled] {
      color: rgba(195, 195, 205, 0.6);
    }

    button:not([disabled]) {
      color: $theme-color;
      border-color: $theme-color;
    }

    .btn-prev {
      margin-right: 10px;
      // border-color: rgba(0, 0, 0, 0.1) !important;
      // color: rgba(0, 0, 0, 0.25) !important;
    }

    .btn-next {
      margin-left: 10px;
    }
  }
}
</style>
