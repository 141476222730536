import request from '@/utils/request'
/**
 * 生成分享链接api
 * @param Object
 * @param music_id 音乐｜视频｜音效id
 * @param type 素材类型 2音乐 3音效 4视频
 */
export function getShareLink(param){
    return request({
        url: '/fontend/share/info',
        method: 'POST',
        data: param
    })
}