<template>
  <div class="app-container search-result-container">
    <!-- 搜索结果提示 -->
    <!-- <div class="result-tips">
      搜索结果
    </div> -->
    <!-- 搜索 -->
    <div class="result-main" :class="{ music: type === 2 }">
      <div v-if="type === 2" class="music-table">
        <div class="column music-title">歌曲信息</div>
        <div class="column music-tags">标签</div>
        <div class="column music-time">时长</div>
      </div>
      <!-- 音乐列表 -->
      <vf-music-list ref="music-list" :data="resultList" :limit="params.limit || 12" :keywordName="params.keyword_name"
        :page.sync="params.page || 1" :total="resultTotal" :position="'right'">
        <template slot-scope="{ scope }">
          <VfMusicItem v-if="type === 2" :data="scope" :keywordName="params.keyword_name"
            :buttons="['cartBtn', 'collect', 'share', 'download']" />
          <sound-effect-card v-if="type === 3" class="sound-effect-card-container" :data="scope"
            :keywordName="params.keyword_name" :buttons="['cart', 'collect', 'share', 'download']" />
          <div v-if="type === 4" class="video-item-container">
            <video-card :data="scope" :keywordName="params.keyword_name" :collected="scope.is_collected"
              @onClick="gotoVideoDetail(scope.object_id)" />
          </div>
        </template>
      </vf-music-list>
      <div v-if="type === 5">
        <pictureList class="pictureList" :list="resultList" :endPage="true" />
      </div>
    </div>
  </div>
</template>

<script>
import { getShareLink } from '@/api/share.js';
import { GOODS_TYPE } from '@/enum/common'
import VfMusicList from '@/components/VfMusicList'
import VfMusicItem from '@/components/VfMuisc'
import SoundEffectCard from '@/components/SoundEffectCard'
import VideoCard from '@/components/VideoCard'
import Buttons from '@/layout/mixins/Buttons'
import pictureList from '@/views/picture/components/pictureList.vue'

export default {
  name: 'SearchResult',
  components: { VideoCard, SoundEffectCard, VfMusicItem, VfMusicList, pictureList },
  data() {
    return {
      goodsTypes: [...Object.values(GOODS_TYPE)],
      resultList: [],
      resultTotal: 0
    }
  },
  computed: {
    params() {
      let { music_id = '', type } = this.$route.query
      return {
        music_id,
        type,
        ...this.$route.query
      }
    },
    type() {
      const type = this.params.type || 2
      return Number(type)
    }
  },
  mixins: [Buttons],
  created() {
    this.getSearchResultList()
  },
  methods: {
    /**
     * 页数发生改变
     * 2021-12-23 18:05:00
     * @author SnowRock
     * @param page
     * @param limit
     */
    // handleChangePackage({ page, limit }) {
    //   this.$router.push({
    //     query: {
    //       ...this.params,
    //       page,
    //       limit
    //     }
    //   })
    //   this.reload()
    // },
    /**
     * 获取搜索结果页面信息
     * 2021-12-23 17:37:03
     * @author SnowRock
     */
    getSearchResultList() {
      const { params } = this
      getShareLink({
        ...params
      }).then((res) => {
        const { data } = res
        this.resultList = data.list.map((i) => {
          return {
            ...i,
            is_collected: i.is_collect
          }
        })
        this.resultTotal = data.total
      }).catch((error) => {
        console.log(error)
      })
    },
    /**
   * 跳转到视频详情页
   * @param {String} videoId 视频id
   */
    async gotoVideoDetail(videoId) {
      // 添加限制播放次数拦截
      const result = await this.handleCheckedTimes(4, videoId)

      if (!result) return
      this.$router.push(`/video/detail?videoId=${videoId}`)
    }
  }
}
</script>

<style lang="scss" scoped>
$max-width: 1200px;

.center {
  width: $max-width;
  margin: 0 auto;
}

.search-result-container {
  padding-top: 70px;
  padding-bottom: 100px;
  min-height: calc(100vh - 268px);
  background-color: #f5f7fa;

  .result-tips {
    @extend .center;
    line-height: 108px;
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(51, 51, 51, 0.6);

    /deep/ {
      .el-button--link-primary {
        font-size: 20px;
      }
    }
  }

  .result-main {
    @extend .center;
    padding-bottom: 64px;
    margin-top: 50px;

    &.music {
      background: #ffffff;
      box-shadow: 0px 0px 20px 0px rgba(33, 48, 83, 0.07);
      border-radius: 12px;
      padding: 0 33px;
      padding-bottom: 64px;

      .music-table {
        line-height: 64px;
        @include font-bold($size: $font-size-md,
          $color: rgba($font-minor-color, 0.8));
        border-bottom: 2px solid rgba(195, 195, 205, 0.36);
        display: flex;
        margin-bottom: 19px;

        .music-title {
          width: 525px;
        }

        .music-tags {
          width: 225px;
        }

        .music-time {}
      }

      .sound-effect-card-container {
        width: calc((100% / 3) - 7px);
        margin-bottom: 10px;
        display: inline-block;
        margin-right: 10px;

        &:nth-of-type(3n) {
          margin-right: 0;
        }
      }
    }

    /deep/ {
      .sound-effect-card-container {
        width: calc((100% / 4) - 9px);
        margin-bottom: 10px;
        display: inline-block;
        margin-right: 10px;

        &:nth-of-type(4n) {
          margin-right: 0;
        }
      }

      .video-item-container {
        width: calc((100% / 4) - 20px);
        margin-bottom: 19px;
        display: inline-block;
        margin-right: 19px;

        &:nth-of-type(4n) {
          margin-right: 0;
        }

        .vf-video-card {
          width: 100%;
          height: auto;

          .cover {
            height: 178px;
            border-radius: 12px;
          }

          .info {
            position: inherit;
            margin-top: -15px;
            margin-bottom: 0;
            margin-left: 26px;
            top: inherit;
            left: inherit;
            right: inherit;
            bottom: inherit;
            width: 230px;
            height: 120px;
            background: #ffffff;
            box-shadow: 0px 8px 12px 0px rgba(33, 48, 83, 0.07);
            border-radius: 24px 0px 24px 24px;
          }
        }
      }

      .music-item-container {
        .tag {
          width: 123px;
          min-width: 123px;
          white-space: nowrap;
        }

        .detail {
          width: 300px;
        }
      }
    }
  }
}
</style>
