<template>
  <div class="vf-music-list-container">
    <div class="vf-music-wrapper">
      <!-- 列表内容 -->
      <div :class="{'video-wrapper': resultSpecialMark}">
        <slot v-for="i in data" :scope="i" />
        <div v-if="!data.length > 0">
          <slot name="empty">
            <el-empty />
          </slot>
        </div>
      </div>
      <!-- 分页器 -->
      <div class="vf-music-pagination" v-if="type !== 3&&type !== 5">
        <!-- <Pagination v-if="total > 0" :position="position" :limit.sync="pageSize" :page.sync="currentPage"
          @pagination="pagination" :total="total" /> -->
        <Pagination style="margin-top: 50px;" :hiddenPage="true" :hidden-page="false" v-if="total > 0" :position="position" :limit.sync="pageSize"
          :page.sync="currentPage" @pagination="pagination" :total="total" />
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
export default {
  name: 'VfMusicList',
  props: {
    // 音乐列表数据
    data: {
      type: Array,
      default: () => []
    },
    total: {
      type: Number,
      default: 0
    },
    page: {
      type: Number,
      default: 1
    },
    limit: {
      type: Number,
      default: 20
    },
    position: {
      type: String,
      default: 'center'
    },
    type: {
      type: Number,
      default: 2
    },
    resultSpecialMark: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    currentPage: {
      get() {
        return this.page
      },
      set(val) {
        this.$emit('update:page', val)
      }
    },
    pageSize: {
      get() {
        return this.limit
      },
      set(val) {
        this.$emit('update:limit', val)
      }
    }
  },
  components: { Pagination },
  methods: {
    pagination(info) {
      this.$emit('pagination', info)
    }
  }
}
</script>

<style lang="scss" scoped>
.video-wrapper{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 134px 27px;
  grid-auto-flow: row;
  margin-bottom: 134px;
}
</style>
